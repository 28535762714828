import Layout from "../../page-components/layout"
import RcDefaultPageTeaser from "../../page-components/teaser/rcDefaultPageTeaser"
import ImmobilienFullCard from "../../pages-parts/immobilien/immobilienFullcard"
import { graphql } from "gatsby"
import React from "react"

const ImmobilienGewerbe = ({ data }) => {

  if (!data) return;

  const immos = data.allStrapiImmos.nodes.filter(n => !n.isBIZ && n.objektkategorie?.objektart?.buero_praxen);

  return <Layout>

    <RcDefaultPageTeaser>
      <h1>Gewerbeimmobilien</h1>
    </RcDefaultPageTeaser>


    <div className="container h-100">
      <div className="row no-gutters h-100 bg-white">

        <div className="col-12 mt-2 pb-5">
          { immos.map(i => <ImmobilienFullCard immo={i} className={"mt-5"} /> )}
        </div>
      </div>

    </div>

  </Layout>
};

export default ImmobilienGewerbe;

export const query = graphql`query ImmoDetailGewerbeListPage {
  allStrapiImmos(
    filter: {title: {ne: "EMPTY"}}
    sort: { fields: created_at, order: DESC }    
  ) {
    nodes {
      id
      isBIZ
      objektnr_extern
      objektkategorie {
        objektart {
          haus {
            haustyp
          }
          buero_praxen {
            buero_typ
          }
        }
      }
      geo {
        plz
        ort
      }
      preise {
        nettokaltmiete
        kaltmiete
        nebenkosten
        zzg_mehrwertsteuer
        freitext_preis
        provisionspflichtig
        kaution
      }
      flaechen {
        nutzflaeche
        gesamtflaeche
        bueroflaeche
        grundstuecksflaeche
        einliegerwohnung
        wohnflaeche
        anzahl_zimmer
        anzahl_schlafzimmer
        anzahl_badezimmer
        anzahl_sep_wc
        anzahl_balkone
        balkon_terrasse_flaeche
        anzahl_terrassen
        anzahl_wohneinheiten
        kellerflaeche
        ladenflaeche
        freiflaeche
      }
      ausstattung {
        klimatisiert
        gartennutzung
        kabel_sat_tv
        barrierefrei
        swimmingpool
        dv_verkabelung
        teekueche
        wg_geeignet
        stromanschlusswert
        hallenhoehe
      }
      freitexte {
        objekttitel
        lage
        objektbeschreibung
      }
      Anhang {
        gruppe
        daten {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`;
