import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const RcImmoHeaderImage = ( { className = "", immo } ) => {

  if (!immo) {
    return null;
  }

  let image = immo.Anhang?.find(a => a.gruppe === "TITELBILD");

  if (!image || !image.daten) {
    image = immo.Anhang?.find(a => a.daten !== null);
  }

  if (!image?.daten?.localFile?.childImageSharp) {
    return null;
  }

  const gatsbyImage = getImage(image.daten.localFile);

  return <GatsbyImage image={gatsbyImage} className={className} />;

};

export default RcImmoHeaderImage;
