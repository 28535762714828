import React from 'react';
import * as styles from './rcImmoPrice.module.scss';

export const getImmoPrice = (immo) => {
  if (!immo) {
    return null;
  }

  let sub = null;
  let isBusiness = false;
  let isRent = false;

  if (immo.preise?.zzg_mehrwertsteuer) {
    isBusiness = true;
    sub = "zzgl. USt";
  }


  let price = undefined;

  if (isBusiness) {

    if (immo.preise?.kaltmiete) {
      price = immo.preise?.kaltmiete;
    } else if (immo.preise?.nettokaltmiete) {
      price = immo.preise?.nettokaltmiete;
      isRent = true;
    } else if (immo.preise?.gesamtmietenetto) {
      price = immo.preise?.gesamtmietenetto;
      isRent = true;
    } else if (immo.preise?.summemietenetto) {
      price = immo.preise?.summemietenetto;
      isRent = true;
    } else if (immo.preise?.monatlichekostenbrutto) {
      // last resort if buisness
      price = immo.preise?.monatlichekostenbrutto;
      isRent = true;
    }

  } else {

    if (immo.preise?.gesamtmietebrutto) {
      price = immo.preise?.gesamtmietebrutto;
      isRent = true;
    } else if (immo?.preise.monatlichekostenbrutto) {
      price = immo.preise?.monatlichekostenbrutto;
      isRent = true;
    } else if (immo.preise?.summemietenetto) {
      // last resort if not business
      price = immo.preise?.summemietenetto;
      isRent = true;
    } else if (immo.preise?.nettokaltmiete) {
      price = immo.preise?.nettokaltmiete;
      isRent = true;
    } else if (immo.preise?.nettomiete) {
      price = immo.preise?.nettomiete;
      isRent = true;
    }

  }

  return { sub, price, isRent };
}

const RcImmoPrice = ( { className = '', immo, showFull = false, subInfoPlacement = 'top' } ) => {

  if (!immo) {
    return null;
  }

  let { sub, price, isRent } = getImmoPrice(immo);
  price = Number.parseFloat(price);

  if (!price) {
    return null;
  }

  return <span className={className}>

    {sub && subInfoPlacement === 'top' && <div className={styles.subInfo}>{sub}</div> }
    { ( Math.round(price * 100) / 100.00).toString().replace('.', ',') + " €" }
    {showFull && isRent && " / Monat"}
    {sub && subInfoPlacement === 'bottom' && <span className={styles.subInfo + ' ' + styles[subInfoPlacement]}>({sub})</span> }

    </span>
};

export default RcImmoPrice;
