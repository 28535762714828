import React from 'react';
import BereichSVG from "../../icons/envato/immoArea.svg"
import RcImmoArea from "./rcImmoArea"

const RcImmoAreaBadge = ({ immo, className = '' } ) => {

  if (!immo) {
    return null;
  }

  return <div className={"d-flex align-items-center " + className}>
    <BereichSVG className={"mr-2"} />
    <RcImmoArea immo={immo} />
  </div>

};

export default RcImmoAreaBadge;
