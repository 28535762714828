import React from 'react';
import Rc2Digits from "../format/rc2Digits"

const RcImmoArea = ( { immo } ) => {

  if (!immo) {
    return null;
  }

  let area = undefined;

  if (immo.flaechen?.gesamtflaeche) {
    area = immo.flaechen?.gesamtflaeche;
  } else if (immo.flaechen?.lagerflaeche) {
    area = immo.flaechen?.lagerflaeche;
  }

  return <> <Rc2Digits value={area} /> m² </>;
}

export default RcImmoArea;
