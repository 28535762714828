import React from 'react';
import * as styles from './immobilienFullcard.module.scss';
import { Link } from "gatsby";

import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg";

import RcImmoHeaderImage from "../../helper-components/immo/rcImmoHeaderImage"
import RcImmoAreaBadge from "../../helper-components/immo/rcImmoAreaBadge"
import RcImmoPrice from "../../helper-components/immo/rcImmoPrice"

const ImmobilienFullCard = ( { immo, sideMargin = true, className = "" } ) => {

  if (!immo) {
    return null;
  }

  let marginCSS = sideMargin ?" mx-0 mx-lg-5 " : " ";

  return <div className={styles.fullcard + " shadow row no-gutters " + marginCSS + className}>

    <div className="col-12 col-lg-4">
      <Link to={"/immobilien/" + immo.id}>
        <RcImmoHeaderImage className={styles.image} immo={immo} />
      </Link>
    </div>

    <div className="col-12 col-lg-8 d-flex flex-column">

      <div className="px-4 pt-4">

        <Link to={"/immobilien/" + immo.id}>
          <h5>{ immo.freitexte.objekttitel }</h5>
        </Link>

        <RcImmoAreaBadge immo={immo} className={"mt-1 mt-lg-3 d-block " + styles.property} />
        <RcImmoPrice immo={immo} showFull={true} className={"mt-1 mt-lg-3 d-block " + styles.property} />


      </div>


      <div className="d-flex w-100 h-100 ml-auto justify-content-end">
        <div className="align-self-end  pr-5 pb-4">
          <Link to={"/immobilien/" + immo.id} className={"mt-3 d-block"}>
            <div className="d-flex align-items-center">
              <ChevronRightSVG className={"mr-1"} />
              Details
            </div>
          </Link>
        </div>
      </div>
    </div>


  </div>

};

export default ImmobilienFullCard;
